import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function CancelIcon (props) {
  return (
    <SvgIcon {...props}>
      <path fill="#FFF" fillRule="evenodd" d="M40 12c9.941 0 18 8.059 18 18s-8.059 18-18 18-18-8.059-18-18 8.059-18 18-18z"/>
      <path fillRule="evenodd" d="M48.985 38.277l-.737.738-7.778-7.779-7.749 7.749-.707-.707 7.749-7.749-7.779-7.778.738-.737 7.778 7.778 7.778-7.778.707.707-7.778 7.778 7.778 7.778z"/>
    </SvgIcon>
  )
}
