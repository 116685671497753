import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function Fail (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 10 10' >
      <rect width="10" height="10"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.00186 1.18073H5.27501V6.27164H4.00186V1.18073ZM5.27314 8.18074C5.27314 8.5322 4.98814 8.81711 4.63657 8.81711C4.285 8.81711 4 8.5322 4 8.18074C4 7.82929 4.285 7.54438 4.63657 7.54438C4.98814 7.54438 5.27314 7.82929 5.27314 8.18074Z" fill="white"/>
    </SvgIcon>
  )
}

Fail.propTypes = {
  fill: PropTypes.string
}
