import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ArrowUpFlat (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 33 10' >
      <path fillRule="evenodd" clipRule="evenodd" d="M30.8323 9.99919C30.5758 9.99919 30.3148 9.95325 30.0609 9.85616L16.5 4.69326L2.93828 9.85616C1.82093 10.2852 0.568342 9.7218 0.142724 8.60271C-0.283762 7.48449 0.277951 6.2319 1.39617 5.80628L16.5 0.0556641L31.6038 5.80628C32.722 6.2319 33.2838 7.48449 32.8573 8.60271C32.5287 9.46695 31.7052 9.99919 30.8323 9.99919Z" />
    </SvgIcon>
  )
}
