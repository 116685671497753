import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function Delete (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 18 19' >
      <path fillRule="evenodd" clipRule="evenodd" d="M6.54368 0.818237H11.4528C12.3565 0.818237 13.0891 1.55086 13.0891 2.4546V3.27278H15.5437C16.4474 3.27278 17.18 4.00541 17.18 4.90915V6.54551C17.18 7.44925 16.4474 8.18187 15.5437 8.18187H15.4784L14.7258 17.1819C14.7258 18.0856 13.9931 18.8182 13.0894 18.8182H4.90759C4.00385 18.8182 3.27122 18.0856 3.27405 17.2498L2.51839 8.18187H2.45277C1.54903 8.18187 0.816406 7.44925 0.816406 6.54551V4.90915C0.816406 4.00541 1.54903 3.27278 2.45277 3.27278H4.90731V2.4546C4.90731 1.55086 5.63994 0.818237 6.54368 0.818237ZM2.45277 4.90915H4.90731H13.0891H15.5437V6.54551H2.45277V4.90915ZM4.15972 8.18187H13.8361L13.0918 17.1139L13.089 17.1819H4.90714L4.15972 8.18187ZM11.4528 2.4546V3.27278H6.54368V2.4546H11.4528Z" fill="#5334b6"/>
    </SvgIcon>
  )
}
