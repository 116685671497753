import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function CalendarIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 18' >
      <path fillRule="evenodd" clipRule="evenodd" d="M13 2H14C15.1 2 16 2.9 16 4V16C16 17.1 15.1 18 14 18H2C0.89 18 0 17.1 0 16L0.00999999 4C0.00999999 2.9 0.89 2 2 2H3V0H5V2H11V0H13V2ZM2 8V16H14V8H2ZM14 6H2V4H14V6ZM8 9H13V14H8V9Z" />
    </SvgIcon>
  )
}
