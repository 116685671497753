import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function UploadMovieIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 16">
      <path fillRule="evenodd" d="M21.729 8.732a.945.945 0 0 1-1.371.028L18.97 7.375v4.617c0 .556-.434 1.008-.971 1.008-.535 0-.97-.452-.97-1.008V7.375L15.642 8.76a.945.945 0 0 1-1.371-.028 1.037 1.037 0 0 1 .027-1.426l3.029-3.025c.042-.042.095-.062.142-.095.055-.038.102-.083.164-.109A.931.931 0 0 1 17.999 4c.126 0 .25.027.367.077.066.028.118.076.177.118.043.03.09.048.129.086l.013.013.001.002 3.016 3.01c.386.386.399 1.024.027 1.426zm-11.714 7.16H4.013v.097H0V-.001h10.764v.018l3.234.076v2.169a6.973 6.973 0 0 0-.993.837v-1.11h-2.019v2.019h1.268a6.972 6.972 0 0 0-.572.993h-.678v1.963h.002v.028h.075A6.97 6.97 0 0 0 11 8c0 .351.034.693.084 1.03h-.065v1.956h.657c.163.345.349.676.564.987h-1.252v1.986h2.018V12.9c.303.31.635.589.992.838v2.172h-3.983v-.018zM.99 13.959h2.005v-2.037H.99v2.037zm0-2.933h2V9.025h-2v2.001zm1.998-4.062V4.988H1.007v1.988h1.722v-.012h.259zm.019-5.004H1.005v2.068h2.002V1.96zm7.008.034H4.013v4.97h6.002v-4.97zm0 6.957H4.013v5.027h6.002V8.951z"/>
    </SvgIcon >
  )
}

UploadMovieIcon.propTypes = {
  fill: PropTypes.string
}
