import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function LogoGradientIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 138 30' >
      <defs>
        <linearGradient id="s8qeb" x1="-652" x2="788" y1="-351" y2="673" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#3d29b1"/><stop offset="1" stopColor="#bd6ad3"/></linearGradient>
        <clipPath id="s8qea"><path d="M29.875 17.353V2.943h7.616c2.838 0 5.008.623 6.51 1.868 1.5 1.245 2.251 3.031 2.251 5.357 0 2.272-.75 4.037-2.251 5.296-1.502 1.26-3.672 1.889-6.51 1.889zm19.858 11.7L42.73 19.2c2.102-.684 3.72-1.8 4.852-3.346 1.133-1.546 1.7-3.441 1.7-5.686 0-3.065-1.038-5.473-3.112-7.225C44.096 1.192 41.23.316 37.573.316H26.846v28.737h3.03v-9.114h7.697c.791 0 1.555-.041 2.292-.123l6.551 9.237zM20.45 26.097c2.06-2.135 3.091-5.241 3.091-9.32V.317h-2.948v16.339c0 3.339-.75 5.83-2.252 7.471-1.501 1.642-3.685 2.463-6.55 2.463-2.84 0-5.01-.82-6.51-2.463-1.502-1.642-2.252-4.132-2.252-7.471V.315H0v16.463c0 4.078 1.037 7.184 3.112 9.319 2.074 2.135 4.967 3.202 8.68 3.202 3.712 0 6.598-1.067 8.659-3.202zm32.79 2.64h21.781v-6.445H61.348V0H53.24zm24.873 0h8.106V0h-8.106zm33.68-22.456V0H89.316v28.737h8.107v-9.853h12.651v-6.28H97.423V6.28zm10.73 16.175V17.2h12.733v-6.076h-12.733V6.281h14.452V0H114.5v28.737h23.01v-6.281z"/></clipPath>
      </defs>
      <g><g><path fill="url(#s8qeb)" d="M29.875 17.353V2.943h7.616c2.838 0 5.008.623 6.51 1.868 1.5 1.245 2.251 3.031 2.251 5.357 0 2.272-.75 4.037-2.251 5.296-1.502 1.26-3.672 1.889-6.51 1.889zm19.858 11.7L42.73 19.2c2.102-.684 3.72-1.8 4.852-3.346 1.133-1.546 1.7-3.441 1.7-5.686 0-3.065-1.038-5.473-3.112-7.225C44.096 1.192 41.23.316 37.573.316H26.846v28.737h3.03v-9.114h7.697c.791 0 1.555-.041 2.292-.123l6.551 9.237zM20.45 26.097c2.06-2.135 3.091-5.241 3.091-9.32V.317h-2.948v16.339c0 3.339-.75 5.83-2.252 7.471-1.501 1.642-3.685 2.463-6.55 2.463-2.84 0-5.01-.82-6.51-2.463-1.502-1.642-2.252-4.132-2.252-7.471V.315H0v16.463c0 4.078 1.037 7.184 3.112 9.319 2.074 2.135 4.967 3.202 8.68 3.202 3.712 0 6.598-1.067 8.659-3.202zm32.79 2.64h21.781v-6.445H61.348V0H53.24zm24.873 0h8.106V0h-8.106zm33.68-22.456V0H89.316v28.737h8.107v-9.853h12.651v-6.28H97.423V6.28zm10.73 16.175V17.2h12.733v-6.076h-12.733V6.281h14.452V0H114.5v28.737h23.01v-6.281z"/></g><g clipPath="url(#s8qea)" opacity="-.9"><path fill="url(#s8qeb)" d="M-652-351H788V673H-652z"/></g></g>
    </SvgIcon>
  )
}
