import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function ProducerChair ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox='0 0 447.00003 482.33002'>
      <style type="text/css">
        .st2{`{fill:none;stroke:${color};stroke-width:6;stroke-miterlimit:10;}`}
        .st3{`{fill:none;stroke:${color};stroke-width:6;stroke-linecap:round;stroke-miterlimit:10;}`}
        .st4{`{fill:none;stroke:${color};stroke-width:6;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
      </style>
      <g
        id="Layer_3"
        transform="translate(-174.78,-200.67)">
        <g
          id="g29">
          <rect
            x="233.58"
            y="252.73"
            className="st2"
            width="330.75"
            height="221.64999"
            id="rect13" />
          <line
            className="st3"
            x1="177.78"
            y1="474.38"
            x2="618.78003"
            y2="474.38"
            id="line15" />
          <line
            className="st3"
            x1="564.52002"
            y1="474.38"
            x2="564.52002"
            y2="203.67"
            id="line17" />
          <line
            className="st3"
            x1="233.77"
            y1="474.38"
            x2="233.77"
            y2="203.67"
            id="line19" />
          <line
            className="st3"
            x1="233.66"
            y1="474.23999"
            x2="575"
            y2="680"
            id="line21" />
          <line
            className="st3"
            x1="564.60999"
            y1="474.23999"
            x2="223.27"
            y2="680"
            id="line23" />
          <polyline
            className="st4"
            points="398.08,315.07 384.23,347.97 347.87,353.17 373.84,379.14 366.91,413.78 398.28,396.75   "
            id="polyline25" />
          <polyline
            className="st4"
            points="400.01,315.07 413.86,347.97 450.23,353.17 424.25,379.14 431.18,413.78 399.82,396.75   "
            id="polyline27" />
        </g>
      </g>
    </SvgIcon>
  )
}

ProducerChair.propTypes = {
  color: PropTypes.string
}
