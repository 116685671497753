import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function PartnerCode ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox='15 160 770 482'>
      <g id="Layer_6" fill={color || '#000'}>
        <line className="st6" x1="509.33" y1="166" x2="509.33" y2="642"/>
        <line className="st7" x1="778" y1="384.33" x2="23" y2="384.33"/>
        <path className="st8" d="M723,638H78c-30.38,0-55-24.62-55-55V221c0-30.38,24.62-55,55-55h645c30.38,0,55,24.62,55,55v362
          C778,613.38,753.38,638,723,638z"/>
        <g id="Layer_7">
          <path className="st7" d="M509.13,355.28c0,0-69.87-152.68-126.53-80.77c-41.15,76.09,100.7,110.05,100.7,110.05"/>
          <path className="st7" d="M536.96,384.59c0,0,154.64-60.42,84.4-119.14c-74.87-43.34-115.04,108.5-115.04,108.5"/>
          <line className="st9" x1="505.33" y1="385.59" x2="425" y2="468"/>
          <line className="st9" x1="512.5" y1="384.5" x2="596.5" y2="468.5"/>
        </g>
        <g>
          <path d="M96.99,473.6c0,6.55-4.91,10.63-12.77,10.63h-5.92v8.11h-6.81v-29.41h12.73C92.07,462.93,96.99,467,96.99,473.6z
            M90.1,473.6c0-3.23-2.1-5.12-6.26-5.12h-5.55v10.21h5.55C88,478.68,90.1,476.79,90.1,473.6z"/>
          <path d="M121.13,479.44v12.9H115v-2.81c-1.22,2.06-3.57,3.15-6.89,3.15c-5.29,0-8.44-2.94-8.44-6.85c0-3.99,2.81-6.76,9.7-6.76
            h5.21c0-2.81-1.68-4.45-5.21-4.45c-2.39,0-4.87,0.8-6.51,2.1l-2.35-4.58c2.48-1.76,6.13-2.73,9.75-2.73
            C117.14,469.4,121.13,472.59,121.13,479.44z M114.58,485.19v-2.31h-4.49c-3.07,0-4.03,1.13-4.03,2.65c0,1.64,1.39,2.73,3.7,2.73
            C111.93,488.26,113.82,487.25,114.58,485.19z"/>
          <path d="M141.08,469.4v6.05c-0.55-0.04-0.97-0.08-1.47-0.08c-3.61,0-6.01,1.97-6.01,6.3v10.67h-6.55v-22.6h6.26v2.98
            C134.91,470.53,137.6,469.4,141.08,469.4z"/>
          <path d="M160.36,491.24c-1.34,0.97-3.32,1.43-5.33,1.43c-5.33,0-8.44-2.73-8.44-8.11v-9.28h-3.49v-5.04h3.49v-5.5h6.55v5.5h5.63
            v5.04h-5.63v9.2c0,1.93,1.05,2.98,2.81,2.98c0.97,0,1.93-0.29,2.65-0.84L160.36,491.24z"/>
          <path d="M187.16,479.39v12.94h-6.55V480.4c0-3.66-1.68-5.33-4.58-5.33c-3.15,0-5.42,1.93-5.42,6.09v11.17h-6.55v-22.6h6.26v2.65
            c1.76-1.93,4.41-2.98,7.48-2.98C183.13,469.4,187.16,472.51,187.16,479.39z"/>
          <path d="M215.34,482.88h-17.1c0.63,2.82,3.02,4.54,6.47,4.54c2.39,0,4.12-0.71,5.67-2.18l3.49,3.78c-2.1,2.39-5.25,3.65-9.33,3.65
            c-7.81,0-12.9-4.92-12.9-11.64c0-6.76,5.17-11.64,12.06-11.64c6.64,0,11.76,4.45,11.76,11.72
            C215.47,481.62,215.38,482.33,215.34,482.88z M198.16,479.06h11.13c-0.46-2.86-2.6-4.71-5.54-4.71
            C200.76,474.35,198.62,476.16,198.16,479.06z"/>
          <path d="M233.86,469.4v6.05c-0.55-0.04-0.97-0.08-1.47-0.08c-3.61,0-6.01,1.97-6.01,6.3v10.67h-6.55v-22.6h6.26v2.98
            C227.68,470.53,230.37,469.4,233.86,469.4z"/>
          <path d="M248.43,477.63c0-8.86,6.81-15.21,15.96-15.21c5.08,0,9.33,1.85,12.1,5.21l-4.37,4.03c-1.97-2.27-4.45-3.44-7.39-3.44
            c-5.5,0-9.41,3.86-9.41,9.41c0,5.54,3.91,9.41,9.41,9.41c2.94,0,5.42-1.18,7.39-3.49l4.37,4.03c-2.77,3.4-7.02,5.25-12.14,5.25
            C255.24,492.84,248.43,486.49,248.43,477.63z"/>
          <path d="M278.12,481.03c0-6.81,5.25-11.64,12.43-11.64c7.18,0,12.39,4.83,12.39,11.64c0,6.8-5.21,11.64-12.39,11.64
            C283.38,492.67,278.12,487.84,278.12,481.03z M296.31,481.03c0-3.91-2.48-6.26-5.76-6.26c-3.28,0-5.8,2.35-5.8,6.26
            c0,3.91,2.52,6.26,5.8,6.26C293.83,487.29,296.31,484.94,296.31,481.03z"/>
          <path d="M330.33,461.16v31.17h-6.26v-2.6c-1.64,1.97-4.03,2.94-7.06,2.94c-6.39,0-11.3-4.54-11.3-11.64
            c0-7.1,4.91-11.64,11.3-11.64c2.77,0,5.12,0.88,6.76,2.73v-10.96H330.33z M323.91,481.03c0-3.91-2.52-6.26-5.75-6.26
            c-3.28,0-5.8,2.35-5.8,6.26c0,3.91,2.52,6.26,5.8,6.26C321.39,487.29,323.91,484.94,323.91,481.03z"/>
          <path d="M358.39,482.88h-17.1c0.63,2.82,3.02,4.54,6.47,4.54c2.39,0,4.12-0.71,5.67-2.18l3.49,3.78c-2.1,2.39-5.25,3.65-9.33,3.65
            c-7.81,0-12.9-4.92-12.9-11.64c0-6.76,5.17-11.64,12.06-11.64c6.64,0,11.76,4.45,11.76,11.72
            C358.52,481.62,358.43,482.33,358.39,482.88z M341.21,479.06h11.13c-0.46-2.86-2.6-4.71-5.54-4.71
            C343.81,474.35,341.67,476.16,341.21,479.06z"/>
        </g>
        <g>
          <path d="M90.01,533.33l-7.1-10.21l-6.97,10.21h-7.81l10.88-14.96l-10.33-14.45h7.73l6.76,9.54l6.64-9.54h7.35l-10.25,14.2
            l10.96,15.21H90.01z"/>
          <path d="M119.58,533.33l-7.1-10.21l-6.97,10.21h-7.81l10.88-14.96l-10.33-14.45h7.73l6.76,9.54l6.64-9.54h7.35l-10.25,14.2
            l10.96,15.21H119.58z"/>
          <path d="M149.15,533.33l-7.1-10.21l-6.97,10.21h-7.81l10.88-14.96l-10.33-14.45h7.73l6.76,9.54l6.64-9.54h7.35l-10.25,14.2
            L157,533.33H149.15z"/>
          <path d="M178.72,533.33l-7.1-10.21l-6.97,10.21h-7.81l10.88-14.96l-10.33-14.45h7.73l6.76,9.54l6.64-9.54h7.35l-10.25,14.2
            l10.96,15.21H178.72z"/>
        </g>
      </g>
    </SvgIcon>
  )
}

PartnerCode.propTypes = {
  color: PropTypes.string
}
