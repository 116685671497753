import React from 'react'

export function ChairIcon () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 86 160">
      <path fill="#000" fillOpacity="0" d="M0 0h86v160H0z"/>
      <path fill="#000" fillOpacity="0" d="M0 42h86v118H0z"/>
      <path fill="#000" fillOpacity="0" d="M17 47h52v96H17z"/>
      <path fill="#000" fillOpacity="0" d="M17 47h52v96H17z"/>
      <path fill="#fff" d="M17 99v14h52V99c-16.5-7.6-35.5-7.6-52 0zm0-52v29c17.1 4 34.9 4 52 0V47c-17.1 3.9-34.9 3.9-52 0zm26 80c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"/>
      <path fill="#37474E" d="M86 92V82h-4V42H68v4.2a114 114 0 0 1-50 0V42H4v40H0v10h4v20H1.9l2.1 1.5v.5h.7L34 135.1c0 .6.1 1.2.2 1.7L1.9 160h15.4l22.9-16.4c.9.3 1.8.4 2.8.4 1 0 1.9-.2 2.8-.4L68.7 160h15.4l-32.3-23.2c.1-.6.2-1.1.2-1.7L81.3 114h.7v-.5l2.1-1.5H82V92h4zM70 44h10v38H70V44zm-52 4.2c16.4 3.6 33.6 3.6 50 0v27a114 114 0 0 1-50 0v-27zm0 29.1c8.2 1.8 16.6 2.7 25 2.7 8.4 0 16.8-.9 25-2.7V82h-4v10h4v5.5c-16-6.9-34-6.9-50 0V92h4V82h-4v-4.7zm0 34.7V99.6c15.9-7.1 34.1-7.1 50 0V112H18zm47.9 2l-18.4 13.2c-1.3-.8-2.9-1.2-4.5-1.2s-3.2.4-4.5 1.2L20.1 114h45.8zM6 44h10v38H6V44zM2 90v-6h18v6H2zm4 2h10v20H6V92zm2.1 22h8.6l20.1 14.5c-.3.3-.6.6-.8.9-.8 1-1.3 2.1-1.7 3.4L8.1 114zm8.6 44H8.1l26.7-19.2c.7 1.6 1.9 2.9 3.3 3.8L16.7 158zm28.9-16.5l-.3.1c-.7.2-1.5.4-2.3.4-.8 0-1.6-.1-2.3-.4l-.3-.1c-2-.8-3.5-2.5-4.1-4.6l-.2-.6c-.1-.4-.1-.7-.1-1.1v-.6c.1-1.6.7-3.1 1.8-4.3 0 0 0-.1.1-.1l.5-.5.1-.1c.2-.2.4-.3.6-.5l.1-.1c1.1-.7 2.4-1.2 3.8-1.2s2.7.4 3.8 1.2l.1.1c.2.1.4.3.6.5 0 0 .1 0 .1.1l.5.5s0 .1.1.1c1 1.2 1.7 2.7 1.8 4.3v.6c0 .4 0 .7-.1 1.1l-.2.6c-.6 2.1-2.1 3.8-4.1 4.6zM77.9 158h-8.6l-21.5-15.4c1.4-.9 2.6-2.2 3.3-3.8L77.9 158zm-26.2-25.2c-.3-1.2-.9-2.4-1.6-3.4-.3-.3-.5-.6-.8-.9l20-14.5h8.6l-26.2 18.8zM80 112H70V92h10v20zM66 90v-6h18v6H66zM11 51c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm64-20c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
      <path fill="#FFCE20" d="M42.159.844l-3.051 6.278-6.828 1.01c-1.224.18-1.715 1.71-.827 2.588l4.94 4.883-1.169 6.898c-.21 1.247 1.084 2.181 2.169 1.598l6.107-3.257 6.108 3.257c1.084.578 2.378-.35 2.168-1.598l-1.169-6.898 4.94-4.883c.888-.877.397-2.408-.827-2.589l-6.828-1.01-3.05-6.277c-.548-1.118-2.132-1.133-2.683 0zM13.249 13.623l-1.709 3.515-3.823.565c-.686.101-.96.959-.463 1.45l2.766 2.735-.654 3.863c-.118.698.607 1.22 1.214.894L14 24.821l3.42 1.824c.607.324 1.332-.196 1.214-.894l-.654-3.863 2.766-2.735c.497-.491.223-1.349-.463-1.45l-3.823-.565-1.709-3.515a.833.833 0 0 0-1.502 0zM72.249 13.623l-1.709 3.515-3.823.565c-.686.101-.96.959-.463 1.45l2.766 2.735-.654 3.863c-.118.698.607 1.22 1.214.894L73 24.821l3.42 1.824c.607.324 1.332-.196 1.214-.894l-.654-3.863 2.766-2.735c.497-.491.223-1.349-.463-1.45l-3.823-.565-1.709-3.515a.833.833 0 0 0-1.502 0z"/>
    </svg>
  )
}
