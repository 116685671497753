import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ArrowDownFlat (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 36 12' >
      <path fillRule="evenodd" fill={props.fill} d="M1.889,5.538 C0.569,5.031 -0.091,3.550 0.416,2.230 C0.923,0.910 2.403,0.251 3.724,0.758 L18.000,6.238 L32.276,0.758 C33.596,0.251 35.077,0.910 35.584,2.230 C36.091,3.550 35.431,5.031 34.111,5.538 L19.251,11.242 C18.838,11.401 18.410,11.445 18.000,11.391 C17.590,11.445 17.162,11.401 16.749,11.242 L1.889,5.538 Z"/>
    </SvgIcon>
  )
}

ArrowDownFlat.propTypes = {
  fill: PropTypes.string
}
