import React from 'react'

export function ArrowUpIcon1280 () {
  return (
    <svg width="162" height="247" viewBox="0 0 162 247" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 245.002C5.5 245.002 21.1 245.002 39.5 245.002C62.5 245.002 78.5 227.502 78.5 206.502C78.5 185.502 78.5 69.5017 78.5 43.5017C78.5 17.5017 95 6.00171 117 6.00171C134.6 6.00171 150.667 6.00171 156.5 6.00171" stroke="url(#paint0_linear)" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M156.5 0.927246V11L161.5 5.96362L156.5 0.927246Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear" x1="78.25" y1="6.00171" x2="78.25" y2="245.002" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="white" stopOpacity="0.25"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export function ArrowUpIcon960 () {
  return (
    <svg width="128" height="197" viewBox="0 0 128 197" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 196C4.39297 196 16.853 196 31.5495 196C49.9201 196 63 182.015 63 165.232C63 148.45 63 55.7469 63 34.9686C63 14.1904 75.8786 5 93.4505 5C107.508 5 125 5 125 5" stroke="url(#paint0_linear)" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M124 0.98414V9L127.978 4.99207L124 0.98414Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear" x1="62.5" y1="5" x2="62.5" y2="196" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="white" stopOpacity="0.25"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export function ArrowUpIcon720 () {
  return (
    <svg width="95" height="148" viewBox="0 0 95 148" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 147C3.30351 147 11.2975 146.906 22.3492 146.906C36.1639 146.906 46 136.362 46 123.709C46 111.056 46 41.3216 46 25.6563C46 9.99103 55.7859 3.06211 69 3.06211C79.5712 3.06211 94 3.06216 94 3.06216" stroke="url(#paint0_linear)" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M92 0V6L95 3L92 0Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear" x1="47" y1="3" x2="47" y2="147" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="white" stopOpacity="0.25"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
