import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function Attention (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 14 14'>
      <path fill="#EB5757" fillRule="evenodd" d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM6.366 3.18h1.273v5.092H6.366V3.18zm1.27 7a.636.636 0 1 1-1.272 0 .636.636 0 0 1 1.273 0z" clipRule="evenodd"/>
    </SvgIcon>
  )
}
