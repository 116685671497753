import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function VideoIcon (props) {
  return (
    <SvgIcon {...props}>
      <path d="M391.2,649.9l245-122.5c22.5-11.3,22.5-43.5,0-54.8l-245-122.5c-20.4-10.2-44.3,4.6-44.3,27.4v245C346.9,645.3,370.8,660,391.2,649.9L391.2,649.9L391.2,649.9z M898.4,132.5H101.6C51.2,132.5,10,174,10,224.8v550.3c0,51,40.9,92.4,91.6,92.4h796.9c50.4,0,91.6-41.5,91.6-92.4V224.8C990,173.8,949.1,132.5,898.4,132.5L898.4,132.5L898.4,132.5z M193.8,775.3c0,17.1-13.1,30.9-30.9,30.9h-60.6c-17.1,0-30.9-13.1-30.9-30.9v-60.6c0-17.1,13.1-30.9,30.9-30.9h60.7c17,0,30.9,13.1,30.9,30.9V775.3L193.8,775.3z M193.8,530.3c0,17.1-13.1,30.9-30.9,30.9h-60.6c-17.1,0-30.9-13.1-30.9-30.9v-60.6c0-17.1,13.1-30.9,30.9-30.9h60.7c17,0,30.9,13.1,30.9,30.9V530.3L193.8,530.3z M193.8,285.3c0,17.1-13.1,30.9-30.9,30.9h-60.6c-17.1,0-30.9-13.1-30.9-30.9v-60.6c0-17.1,13.1-30.9,30.9-30.9h60.7c17,0,30.9,13.1,30.9,30.9V285.3L193.8,285.3z M745,806.3H255V193.7h490V806.3z M928.8,775.3c0,17.1-13.1,30.9-30.9,30.9h-60.7c-17,0-30.9-13.1-30.9-30.9v-60.6c0-17.1,13.1-30.9,30.9-30.9h60.7c17,0,30.9,13.1,30.9,30.9V775.3z M928.8,530.3c0,17.1-13.1,30.9-30.9,30.9h-60.7c-17,0-30.9-13.1-30.9-30.9v-60.6c0-17.1,13.1-30.9,30.9-30.9h60.7c17,0,30.9,13.1,30.9,30.9V530.3z M928.8,285.3c0,17.1-13.1,30.9-30.9,30.9h-60.7c-17,0-30.9-13.1-30.9-30.9v-60.6c0-17.1,13.1-30.9,30.9-30.9h60.7c17,0,30.9,13.1,30.9,30.9V285.3z" fill="#FFFFFF"/>
    </SvgIcon>
  )
}
