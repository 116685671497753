import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function SortIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 3.234 4.4289999' >
      <g transform="translate(0,-292.571)"><g transform="translate(-87.05863,141.33354)"><path fillRule="evenodd" clipRule="evenodd" d="m 88.116959,151.23703 1.05833,1.05569 H 88.38154 v 1.85473 h -0.52916 v -1.85473 h -0.79375 z"/><path fillRule="evenodd" clipRule="evenodd" d="m 89.234614,155.66646 -1.05833,-1.05569 h 0.79375 v -1.85473 h 0.529159 v 1.85473 h 0.79375 z"/></g></g><path fillRule="evenodd" clipRule="evenodd" d="M2.99976 14.9998L3.80008 13.9997L15.9999 1.39988L14.5998 -0.000244141L1.39986 13.5996L0 14.9998L14.5998 29.9998L15.9999 28.5997L3.80008 15.9998L2.99976 14.9998Z"/>
    </SvgIcon>
  )
}
