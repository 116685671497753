import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function DownloadFile (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 22'>
      <path fillRule="evenodd" d="M15.414,21.414 C15.039,21.789 14.530,22.000 14.000,22.000 L2.000,22.000 C1.470,22.000 0.961,21.789 0.586,21.414 C0.211,21.039 -0.000,20.530 -0.000,20.000 L-0.000,6.000 L6.000,-0.000 L14.000,-0.000 C15.110,-0.000 16.000,0.890 16.000,2.000 L16.000,20.000 C16.000,20.530 15.789,21.039 15.414,21.414 ZM7.000,1.500 L1.500,7.000 L7.000,7.000 L7.000,1.500 ZM13.729,15.268 C13.359,14.868 12.744,14.855 12.358,15.240 L10.971,16.625 L10.971,12.008 C10.971,11.452 10.536,11.000 10.001,11.000 C9.464,11.000 9.030,11.452 9.030,12.008 L9.030,16.625 L7.642,15.240 C7.257,14.855 6.642,14.868 6.271,15.268 C5.899,15.670 5.912,16.308 6.298,16.694 L9.314,19.705 L9.315,19.706 L9.328,19.719 C9.367,19.757 9.415,19.775 9.457,19.805 C9.516,19.847 9.568,19.895 9.634,19.923 C9.751,19.973 9.876,20.000 10.001,20.000 C10.126,20.000 10.250,19.973 10.368,19.923 C10.429,19.897 10.476,19.852 10.531,19.815 C10.578,19.781 10.631,19.761 10.673,19.719 L13.702,16.694 C14.089,16.308 14.100,15.670 13.729,15.268 Z"/>
    </SvgIcon>
  )
}
