import React from 'react'

export function ArrowDwnIcon1280 () {
  return (
    <svg width="194" height="221" viewBox="0 0 194 221" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M194 215.002L184 215.002L189 220.002L194 215.002Z" fill="white"/>
      <path d="M0 1.50171C44.3333 1.50171 128.2 1.50171 149 1.50171C175 1.50171 189 19.0017 189 40.5017C189 57.7017 189 164.002 189 215.002" stroke="url(#paint0_linear)" strokeWidth="2"/>
      <defs>
        <linearGradient id="paint0_linear" x1="89.5" y1="1.50171" x2="89.5" y2="215.002" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
      </defs>
    </svg>

  )
}

export function ArrowDwnIcon960 () {
  return (
    <svg width="156" height="174" viewBox="0 0 156 174" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1C35.6543 1 103.103 1 119.831 1C140.741 1 152 14.9344 152 32.0539C152 45.7494 152 130.391 152 171" stroke="url(#paint0_linear)" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M155.956 170L148 170L151.978 173.979L155.956 170Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear" x1="71.9788" y1="1" x2="71.9788" y2="171" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export function ArrowDwnIcon720 () {
  return (
    <svg width="116" height="131" viewBox="0 0 116 131" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1C26.5062 1 76.6487 1 89.0847 1C104.63 1 113 11.4918 113 24.3817C113 34.6937 113 98.4239 113 129" stroke="url(#paint0_linear)" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M116 128L110 128L113 131L116 128Z" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear" x1="53.5106" y1="1" x2="53.5106" y2="129" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
      </defs>
    </svg>

  )
}
