import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function NoteIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.02965 15L7.63987 12.0148H13.6334C14.4701 12.0148 15 11.3366 15 10.5V1.51483C15 0.678211 14.4701 0 13.6334 0H1.51483C0.678211 0 0 0.678211 0 1.51483V10.5C0 11.3366 0.678211 12.0148 1.51483 12.0148H3.02965V15ZM7.20543 10.9999L4.02966 12.9999V10.9999H1V0.999934H14V10.9999H7.20543ZM4 8V7H9V8H4ZM4 4V5H11V4H4Z"/>
    </SvgIcon>
  )
}
