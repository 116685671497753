import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

let logoGradientInc = 0
export function LogoIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 148 35' >
      {!props.fill && ++logoGradientInc &&
      <defs>
        <linearGradient id={`logo-gradient-${logoGradientInc}`} x1="148" y1="17.2481" x2="-1.87414e-07" y2="17.2481" gradientUnits="userSpaceOnUse">
          <stop style={{ transition: '.3s ease' }} stopColor="#EE598E"/>
          <stop style={{ transition: '.3s ease' }} offset="1" stopColor="#ED8469"/>
        </linearGradient>
      </defs>
      }

      <path fill={props.fill || `url(#logo-gradient-${logoGradientInc})`} d="M58.0264 25.4042V0H46.9427V34.0951H75.3692L69.3709 25.4042H58.0264ZM23.4714 19.1869C23.4714 22.8638 22.689 25.6048 21.059 27.4098C19.4291 29.2148 17.0167 30.0839 13.9524 30.0839C7.75859 30.1508 4.56388 26.5407 4.56388 19.1869V0.0668532H0V19.4543C0 24.3346 1.23877 28.0783 3.7163 30.6188C6.19383 33.226 9.51894 34.4962 13.9524 34.4962C18.3859 34.4962 21.9066 33.226 24.3841 30.6188C26.8617 28.0115 28.1004 24.3346 28.1004 19.3874V0H23.4714V19.1869ZM75.63 22.7301V34.0951H86.7137V0.0668532H75.63V22.7301ZM131.048 25.4042V20.7245H145.392V12.7021H131.048V8.69091H147.478C146.957 6.48476 145.848 4.67972 144.153 3.20895C141.871 1.27021 138.807 0.20056 135.026 0.0668532H134.178C134.113 0.0668532 134.048 0.0668532 133.982 0.0668532H120.617H119.965V0.133706H90.6255V34.162H101.709V20.7913H116.053V12.7021H101.709L101.774 8.69091H119.965V34.0951H148V25.4042H131.048ZM35.6634 12.6353V8.28979H31.23V34.0951H35.859V21.2593C35.859 18.4515 36.5762 16.3122 38.0106 14.7746C39.4449 13.2369 41.4009 12.5015 43.8784 12.5015C44.3348 12.5015 44.6608 12.5015 44.9216 12.5684V8.02238C42.7048 8.02238 40.7489 8.4235 39.1841 9.15889C37.6846 9.96112 36.511 11.0976 35.6634 12.6353Z"/>
    </SvgIcon>
  )
}

LogoIcon.propTypes = {
  fill: PropTypes.string
}
