import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function PauseIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 9 11">
      <g><g><path fill="#01b7d7" d="M.898.714a.77.77 0 0 1 1.538 0v9.15a.77.77 0 0 1-1.538 0zm5.954 0a.77.77 0 0 1 1.538 0v9.15a.77.77 0 0 1-1.538 0z" /></g></g>
    </SvgIcon>
  )
}
