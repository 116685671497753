import React from 'react'
import PropTypes from 'prop-types'

export function HandIcon (props) {
  return (
    <svg width="78" height="77" viewBox="0 0 78 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.1491 41.4542C34.2902 41.6891 34.4781 41.8771 34.7131 42.1121C37.6743 45.6363 42.2776 47.3304 46.8321 46.7235C56.1292 45.6037 55.9933 52.037 55.9933 52.037C55.9933 52.037 48.5273 52.7356 42.2361 54.4212C37.729 55.6386 32.8918 55.1183 28.8985 52.7203C28.6635 52.5792 28.3816 52.3912 28.1467 52.2501C25.6096 50.5576 22.51 50.1795 19.6462 51.0695" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M53.924 47.9971L61.7153 43.0256L70.2019 26.3617C70.2019 26.3617 67.2421 24.6219 64.287 28.799L57.9553 38.8902L51.525 42.7828" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M67.3374 26.1245L69.6349 21.6652C69.6349 21.6652 65.4541 19.7366 62.4526 24.8059L55.2292 35.413L49.0333 38.8831" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M64.0007 22.788L63.7659 22.7408C61.2767 22.3163 58.7888 23.3944 57.3347 25.5064L53.0662 31.7486L46.7764 35.2187" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M4.8007 43.1686L8.17912 39.7902C10.9476 37.0218 14.045 34.6763 17.4715 32.7536C22.1185 30.1745 27.6124 29.7561 32.5914 31.5444L36.725 33.0503C39.1206 33.9444 41.8441 33.7587 44.0971 32.4456L50.246 29.0224L50.293 28.9755L50.9028 28.1776C52.3101 26.3004 54.7513 25.457 57.0527 25.9753" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.8012 43.1686L11.9974 57.9663L17.5837 55.5757C19.4614 54.7319 20.305 52.5725 19.4583 50.6934L12.4928 36.1805" stroke="white" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.56 8.81873L44.1615 5.1742L55.1652 14.4067L40.381 45.4707L12.0453 25.9609L16.9586 12.4635L30.56 8.81873Z" fill={`url(#paint0_linear${props.id})`}/>
      <path d="M44.1615 5.1742L44.5472 4.71456L44.3079 4.51381L44.0062 4.59465L44.1615 5.1742ZM30.56 8.81873L30.4047 8.23918L30.4047 8.23918L30.56 8.81873ZM55.1652 14.4067L55.707 14.6646L55.9064 14.2455L55.5508 13.9471L55.1652 14.4067ZM40.381 45.4707L40.0407 45.9649L40.6204 46.364L40.9228 45.7286L40.381 45.4707ZM12.0453 25.9609L11.4815 25.7557L11.3227 26.1919L11.705 26.4551L12.0453 25.9609ZM16.9586 12.4635L16.8033 11.884L16.5017 11.9648L16.3948 12.2583L16.9586 12.4635ZM44.0062 4.59465L30.4047 8.23918L30.7152 9.39829L44.3168 5.75376L44.0062 4.59465ZM55.5508 13.9471L44.5472 4.71456L43.7759 5.63384L54.7795 14.8664L55.5508 13.9471ZM40.9228 45.7286L55.707 14.6646L54.6234 14.1489L39.8392 45.2129L40.9228 45.7286ZM40.7213 44.9765L12.3856 25.4668L11.705 26.4551L40.0407 45.9649L40.7213 44.9765ZM12.6091 26.1662L17.5224 12.6687L16.3948 12.2583L11.4815 25.7557L12.6091 26.1662ZM17.1139 13.0431L30.7153 9.39829L30.4047 8.23918L16.8033 11.884L17.1139 13.0431Z" fill="white"/>
      <path d="M29.1682 24.7604L27.266 9.56611L33.8435 7.80368L39.7934 21.9134L40.733 46.3334L29.1682 24.7604Z" stroke="white"/>
      <path d="M18.7233 26.3489L20.8954 11.273L39.731 6.22604L49.15 18.1961L40.5192 45.873L18.7233 26.3489Z" stroke="white"/>
      <path d="M12.0332 25.9148L19.0188 26.1136L28.9797 24.4798L39.7597 21.5913L49.2029 18.0258L55.1532 14.3608" stroke="white" strokeWidth="1.2"/>
      <path d="M20.6714 12.3684L17.0318 12.3083L20.0243 9.95356L26.6564 7.65886L33.4179 5.84713L39.8258 4.64777L44.0777 5.06138L40.4728 7.06259L34.4531 9.71083L27.6917 11.5226L20.6714 12.3684Z" fill={`url(#paint1_linear${props.id})`} stroke="white" strokeWidth="1.2" strokeLinejoin="bevel"/>
      <defs>
        <linearGradient id={`paint0_linear${props.id}`} x1="54.0864" y1="36.1637" x2="20.688" y2="12.6749" gradientUnits="userSpaceOnUse">
          <stop stopColor="#32D7E5"/>
          <stop offset="1" stopColor="#B182F7"/>
        </linearGradient>
        <linearGradient id={`paint1_linear${props.id}`} x1="40.4039" y1="7.52234" x2="38.2341" y2="2.4091" gradientUnits="userSpaceOnUse">
          <stop stopColor="#32D7E5"/>
          <stop offset="1" stopColor="#B182F7"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

HandIcon.propTypes = {
  id: PropTypes.string.isRequired
}
