import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function Block (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 19 19' >
      <path stroke="#FF3B30" strokeWidth="1" d="M14.657 14.657A8 8 0 1 1 3.343 3.342a8 8 0 0 1 11.314 11.315zm-1.418-1.418c2.101-2.102 2.307-5.364.647-7.711l-8.358 8.358c2.347 1.66 5.609 1.454 7.711-.647zM4.736 4.736c-2.11 2.11-2.307 5.389-.625 7.738l8.363-8.363c-2.349-1.682-5.628-1.485-7.738.625z"/>
    </SvgIcon>
  )
}

Block.propTypes = {
  fill: PropTypes.string
}
