import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function Flag (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16' >
      <path d="M0 2C0 2.8 0.4 3.4 1.1 3.8V15C1.1 15.6 1.6 16 2.1 16C2.7 16 3.1 15.6 3.1 15V8V3.8C3.7 3.5 4.1 2.8 4.1 2.1C4.1 0.999999 3.2 0.0999989 2.1 0.0999989C0.9 -1.09524e-06 0 0.899999 0 2ZM4.1 4V12C4.1 12 4.1 11 7.1 11C8 11 8.7 11.1 9.1 11.3V12C9.1 12 9.2 13 12.1 13C15 13 15.1 12 15.1 12V4C15.1 4 15.1 5 12.1 5C11.2 5 10.5 4.9 10.1 4.8V4C10.1 4 10 3 7.1 3C4.1 3 4.1 4 4.1 4Z"/>
    </SvgIcon>
  )
}

Flag.propTypes = {
  fill: PropTypes.string
}
