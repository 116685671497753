import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function NextIcon (props) {
  return (
    <SvgIcon {...props}>
      <path fill="#FFF" fillRule="evenodd" d="M22.975 11.526L12.374 22.125l-.715-.714 9.369-9.369H0v-1.043h20.995l-9.357-9.358.759-.759 10.446 10.446-.033.033.165.165z"/>
    </SvgIcon>
  )
}
