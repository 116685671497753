import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function BurgerIcon (props) {
  return (
    <SvgIcon {...props} viewBox={ null }>
      <path fill={ props.fill } fillRule="evenodd" d="M0 16v-2.087h25V16H0zm0-9.044h25v2.087H0V6.956zM0 0h25v2.087H0V0z"/>
    </SvgIcon>
  )
}

BurgerIcon.propTypes = {
  fill: PropTypes.string.isRequired
}
