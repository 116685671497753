import React from 'react'

export function Pictures (props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 127 207" {...props}>
      <path fill="none" d="M0 0h123v199H0z"/>
      <path fill="none" d="M0 0h123v199H0z"/>
      <path fill="none" d="M0 0h99v118H0z"/>
      <path fill="#37474E" d="M26 105c0 3.9 3.1 7 7 7s7-3.1 7-7-3.1-7-7-7-7 3.1-7 7zm12 0c0 2.8-2.2 5-5 5s-5-2.2-5-5 2.2-5 5-5 5 2.2 5 5zm20.2 11H7.8c-3.2 0-5.8-2.6-5.8-5.8V94h43c.6 0 1-.4 1-1s-.4-1-1-1H2V14h62v45c0 .6.4 1 1 1s1-.4 1-1V7.8C66 3.5 62.5 0 58.2 0H7.8C3.5 0 0 3.5 0 7.8v102.4c0 4.3 3.5 7.8 7.8 7.8h50.4c.6 0 1-.4 1-1s-.5-1-1-1zM2 7.8C2 4.6 4.6 2 7.8 2h50.4C61.4 2 64 4.6 64 7.8V12H2V7.8zM33 6h-8c-.6 0-1 .4-1 1s.4 1 1 1h8c.6 0 1-.4 1-1s-.4-1-1-1zm8 0h-2c-.6 0-1 .4-1 1s.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1z"/>
      <g filter="url(#a)">
        <path fill="#6AC4E7" d="M54 64h46v46H54z"/>
        <path stroke="#fff" strokeWidth="4" d="M56 66h42v42H56z"/>
      </g>
      <g filter="url(#b)">
        <path fill="#1ED39F" d="M77 120h46v46H77z"/>
        <path stroke="#fff" strokeWidth="4" d="M79 122h42v42H79z"/>
      </g>
      <g filter="url(#c)">
        <path fill="#FFCE20" d="M21 153h46v46H21z"/>
        <path stroke="#fff" strokeWidth="4" d="M23 155h42v42H23z"/>
      </g>
      <circle cx="82.5" cy="81.5" r="7.5" fill="#FFF487"/>
      <ellipse cx="102.615" cy="148.5" fill="#FB6D69" rx="10.385" ry="10.5"/>
      <ellipse cx="96.385" cy="148.5" fill="#FB6D69" rx="10.385" ry="10.5"/>
      <path fill="#13BE8D" d="M102.5 128c-3.6 0-3.833 6-3.5 9 .25-2.333 1.399-7 4-7 .344 0 .5-2-.5-2z"/>
      <path fill="#3EB2E0" d="M58 93h38v13H58z"/>
      <path fill="none" d="M25 157h38v38H25z"/>
      <mask id="d" width="38" height="38" x="25" y="157" maskUnits="userSpaceOnUse">
        <path fill="#FFCE20" d="M25 157h38v38H25z"/>
      </mask>
      <g mask="url(#d)">
        <ellipse cx="32.842" cy="163.088" fill="#C4C4C4" rx="19.491" ry="10.088"/>
        <ellipse cx="32.842" cy="163.088" fill="url(#e)" rx="19.491" ry="10.088"/>
        <ellipse cx="55.877" cy="173.176" fill="#C4C4C4" rx="19.491" ry="10.088"/>
        <ellipse cx="55.877" cy="173.176" fill="url(#f)" rx="19.491" ry="10.088"/>
        <ellipse cx="19.553" cy="183.985" fill="#C4C4C4" rx="34.553" ry="18.015"/>
        <ellipse cx="19.553" cy="183.985" fill="url(#g)" rx="34.553" ry="18.015"/>
        <ellipse cx="66.509" cy="186.147" fill="#C4C4C4" rx="19.491" ry="10.088"/>
        <ellipse cx="66.509" cy="186.147" fill="url(#h)" rx="19.491" ry="10.088"/>
        <ellipse cx="11.579" cy="186.147" fill="#C4C4C4" rx="19.491" ry="10.088"/>
        <ellipse cx="11.579" cy="186.147" fill="url(#i)" rx="19.491" ry="10.088"/>
        <ellipse cx="34.614" cy="190.471" fill="#C4C4C4" rx="19.491" ry="10.088"/>
        <ellipse cx="34.614" cy="190.471" fill="url(#j)" rx="19.491" ry="10.088"/>
      </g>
      <defs>
        <filter id="a" width="54" height="54" x="50" y="64" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 0"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <filter id="b" width="54" height="54" x="73" y="120" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 0"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <filter id="c" width="54" height="54" x="17" y="153" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 0"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <linearGradient id="e" x1="32.842" x2="32.842" y1="153" y2="165.25" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF487"/>
          <stop offset="1" stopColor="#FFCE20"/>
        </linearGradient>
        <linearGradient id="f" x1="55.877" x2="55.877" y1="163.088" y2="175.338" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF487"/>
          <stop offset="1" stopColor="#FFCE20"/>
        </linearGradient>
        <linearGradient id="g" x1="19.553" x2="19.553" y1="165.97" y2="187.846" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF487"/>
          <stop offset="1" stopColor="#FFCE20"/>
        </linearGradient>
        <linearGradient id="h" x1="66.509" x2="66.509" y1="176.059" y2="188.309" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF487"/>
          <stop offset="1" stopColor="#FFCE20"/>
        </linearGradient>
        <linearGradient id="i" x1="11.579" x2="11.579" y1="176.059" y2="188.309" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF487"/>
          <stop offset="1" stopColor="#FFCE20"/>
        </linearGradient>
        <linearGradient id="j" x1="34.614" x2="34.614" y1="180.382" y2="192.632" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF487"/>
          <stop offset="1" stopColor="#FFCE20"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
