import React from 'react'
import PropTypes from 'prop-types'

export function ProgressLine ({ isComplete, isFailed, id, ...props }) {
  let colorFrom = '#3d29b1'
  let colorTo = '#bd6ad3'
  if (isComplete) {
    colorFrom = colorTo = '#27ae60'
  }
  if (isFailed) {
    colorFrom = colorTo = '#eb5757'
  }
  return (
    <svg {...props} >
      <linearGradient id={`linear-gradient-${id}`}>
        <stop offset="0%" style={{ stopColor: colorFrom, transition: '.2s ease-out' }} />
        <stop offset="100%" style={{ stopColor: colorTo, transition: '.2s ease-out' }} />
      </linearGradient>
      <rect fill={`url(#linear-gradient-${id}`} width='100%' height='100%'/>
    </svg>
  )
}

ProgressLine.propTypes = {
  id: PropTypes.any,
  isComplete: PropTypes.bool,
  isFailed: PropTypes.bool
}
