import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function FilmIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 16">
      <path fill={props.fill} fillRule="evenodd" d="M10.015 15.91v-.018H4.014v.098H0V0h10.764v.017l3.234.077V15.91h-3.983zm2.98-8.918V5.001h-1.991v1.964h.002v.027h1.989zm-1.976 2.039v1.955h1.956V9.031h-1.956zm-1.004 4.947V8.952H4.014v5.026h6.001zM2.988 6.965V4.989H1.007v1.987h1.722v-.011h.259zM.99 13.959h2.006v-2.037H.99v2.037zm0-2.933h2.001v-2H.99v2zM3.007 1.96H1.005v2.068h2.002V1.96zm1.007.034v4.971h6.001V1.994H4.014zm8.991-.004h-2.019v2.019h2.019V1.99zm.001 9.984h-2.018v1.986h2.018v-1.986z"/>
    </SvgIcon >
  )
}

FilmIcon.propTypes = {
  fill: PropTypes.string.isRequired
}
