import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function LinkToTop (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 19 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9997 10C17.7997 10 17.3997 10 17.1997 9.80005L9.99969 2.40005L2.79969 9.80005C2.39969 10 1.59969 10 1.19969 9.80005C0.79969 9.60005 0.99969 8.60005 1.19969 8.20005L9.19969 0.200049C9.59969 4.88311e-05 10.3997 4.88311e-05 10.7997 0.200049L18.7997 8.20005C18.9997 8.60005 18.9997 9.40005 18.7997 9.80005C18.5997 10 18.1997 10 17.9997 10Z" fill="url(#paint0_linear)"/>
      <path d="M10 24C9.4 24 9 23.6 9 23V1C9 0.4 9.4 0 10 0C10.6 0 11 0.4 11 1V23C11 23.6 10.6 24 10 24Z" fill="url(#paint1_linear)"/>
      <defs>
        <linearGradient id="paint0_linear" x1="18.9497" y1="5.02505" x2="0.96875" y2="5.02505" gradientUnits="userSpaceOnUse">
          <stop style={{ transition: '.3s ease' }} className='gradientStop1' stopColor="#EE598E"/>
          <stop style={{ transition: '.3s ease' }} className='gradientStop2' offset="1" stopColor="#ED8469"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="11" y1="12" x2="9" y2="12" gradientUnits="userSpaceOnUse">
          <stop style={{ transition: '.3s ease' }} className='gradientStop1' stopColor="#EE598E"/>
          <stop style={{ transition: '.3s ease' }} className='gradientStop2' offset="1" stopColor="#ED8469"/>
        </linearGradient>
      </defs>
    </SvgIcon>

  )
}
